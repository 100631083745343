<template>
  <div>
    <CRow>
      <CCol col="12" sm="6" lg="4">
        <CWidgetIcon
          header="Disembunyikan"
          text="PIN Transaksi"
          color="primary"
          :icon-padding="false"
        >
          <CIcon name="cil-envelope-closed" />
          <template #footer>
            <CCardFooter class="px-2 py-2">
              <transition name="slide">
                <CRow v-if="!formResetPin.show">
                  <CCol class="text-right">
                    <CButton
                      color="warning"
                      size="sm"
                      @click="formResetPin.show = !formResetPin.show"
                    >
                      <CIcon name="cil-pencil" /> Ubah
                    </CButton>
                  </CCol>
                </CRow>
              </transition>
              <transition name="slide">
                <div v-if="formResetPin.show">
                  <CForm @submit.prevent="submitResetPin()">
                    <CRow>
                      <CCol>
                        <CInput
                          placeholder="password"
                          type="password"
                          v-model="formResetPin.password"
                          :invalid-feedback="validatorResetPin.password"
                          :is-valid="validatorResetPin.password ? false : true"
                        >
                        </CInput>
                        <CInput
                          placeholder="PIN Baru"
                          type="number"
                          v-model="formResetPin.pin"
                          :invalid-feedback="validatorResetPin.pin"
                          :is-valid="validatorResetPin.pin ? false : true"
                        >
                        </CInput>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton
                          size="sm"
                          color="warning"
                          @click="formResetPin.show = !formResetPin.show"
                          >Tutup</CButton
                        >
                      </CCol>
                      <CCol col="6" class="text-right">
                        <CButton type="submit" size="sm" color="success"
                          >Simpan</CButton
                        >
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </transition>
            </CCardFooter>
          </template>
        </CWidgetIcon>
      </CCol>
      <CCol col="12" sm="6" lg="4">
        <CWidgetIcon
          :header="profile.email"
          text="Email"
          :color="profile.email_verification ? 'success' : 'danger'"
          :icon-padding="false"
        >
          <CIcon name="cil-envelope-closed" />
          <template #footer>
            <CCardFooter class="px-2 py-2">
              <transition name="slide">
                <CRow
                  v-if="!formChangeEmail.show && !formVerificationEmail.show"
                >
                  <CCol col="6" class="text-left">
                    <CButton
                      v-if="!profile.email_verification"
                      color="success"
                      size="sm"
                      @click="
                        formVerificationEmail.show = !formVerificationEmail.show
                      "
                    >
                      <CIcon name="cil-check" /> Verifikasi</CButton
                    >
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton
                      color="warning"
                      size="sm"
                      @click="formChangeEmail.show = !formChangeEmail.show"
                      ><CIcon name="cil-pencil" /> Ubah</CButton
                    >
                  </CCol>
                </CRow>
              </transition>
              <transition name="slide">
                <div v-if="formVerificationEmail.show">
                  <CRow class="mb-2">
                    <CCol class="text-center">
                      <CButton
                        color="success"
                        size="sm"
                        variant="outline"
                        @click="sendVerificationEmail()"
                      >
                        Kirim Email OTP
                      </CButton>
                    </CCol>
                  </CRow>
                  <CForm @submit.prevent="submitVerificationEmail()">
                    <CRow>
                      <CCol>
                        <CInput
                          placeholder="Masukkan OTP"
                          type="number"
                          v-model="formVerificationEmail.otp"
                          :invalid-feedback="validatorVerificationEmail.otp"
                          :is-valid="
                            validatorVerificationEmail.otp ? false : true
                          "
                        >
                        </CInput>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton
                          size="sm"
                          color="warning"
                          @click="
                            formVerificationEmail.show =
                              !formVerificationEmail.show
                          "
                          >Tutup</CButton
                        >
                      </CCol>
                      <CCol col="6" class="text-right">
                        <CButton type="submit" size="sm" color="success"
                          >Verifikasi</CButton
                        >
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </transition>
              <transition name="slide">
                <div v-if="formChangeEmail.show">
                  <CForm @submit.prevent="submitChangeEmail()">
                    <CRow>
                      <CCol>
                        <CInput
                          placeholder="Email Baru"
                          type="email"
                          v-model="formChangeEmail.email"
                          :invalid-feedback="validatorChangeEmail.email"
                          :is-valid="validatorChangeEmail.email ? false : true"
                        >
                        </CInput>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton
                          size="sm"
                          color="warning"
                          @click="formChangeEmail.show = !formChangeEmail.show"
                          >Tutup</CButton
                        >
                      </CCol>
                      <CCol col="6" class="text-right">
                        <CButton type="submit" size="sm" color="success"
                          >Simpan</CButton
                        >
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </transition>
            </CCardFooter>
          </template>
        </CWidgetIcon>
      </CCol>
      <CCol col="12" sm="6" lg="4">
        <CWidgetIcon
          :header="profile.phone_number"
          text="Nomor HP"
          :color="profile.phone_verification ? 'success' : 'danger'"
          :icon-padding="false"
        >
          <CIcon name="cil-screen-smartphone" />
          <template #footer>
            <CCardFooter class="px-2 py-2">
              <transition name="slide">
                <CRow
                  v-if="
                    !formChangePhoneNumber.show &&
                    !formVerificationPhoneNumber.show
                  "
                >
                  <CCol col="6" class="text-left">
                    <CButton
                      v-if="!profile.phone_verification"
                      color="success"
                      size="sm"
                      @click="
                        formVerificationPhoneNumber.show =
                          !formVerificationPhoneNumber.show
                      "
                      ><CIcon name="cil-check" /> Verifikasi</CButton
                    >
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton
                      color="warning"
                      size="sm"
                      @click="
                        formChangePhoneNumber.show = !formChangePhoneNumber.show
                      "
                      ><CIcon name="cil-pencil" /> Ubah</CButton
                    >
                  </CCol>
                </CRow>
              </transition>

              <transition name="slide">
                <div v-if="formVerificationPhoneNumber.show">
                  <CRow class="mb-2">
                    <CCol class="text-center">
                      <CButton
                        color="success"
                        size="sm"
                        variant="outline"
                        class="mx-1"
                        @click="sendVerificationPhoneNumber('whatsapp')"
                      >
                        Whatsapp OTP
                      </CButton>
                      <CButton
                        color="primary"
                        size="sm"
                        variant="outline"
                        class="mx-1"
                        @click="sendVerificationPhoneNumber('sms')"
                      >
                        SMS OTP
                      </CButton>
                    </CCol>
                  </CRow>
                  <CForm @submit.prevent="submitVerificationPhoneNumber()">
                    <CRow>
                      <CCol>
                        <CInput
                          placeholder="Masukkan OTP"
                          type="number"
                          v-model="formVerificationPhoneNumber.otp"
                          :invalid-feedback="
                            validatorVerificationPhoneNumber.otp
                          "
                          :is-valid="
                            validatorVerificationPhoneNumber.otp ? false : true
                          "
                        >
                        </CInput>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton
                          size="sm"
                          color="warning"
                          @click="
                            formVerificationPhoneNumber.show =
                              !formVerificationPhoneNumber.show
                          "
                        >
                          Tutup
                        </CButton>
                      </CCol>
                      <CCol col="6" class="text-right">
                        <CButton type="submit" size="sm" color="success"
                          >Verifikasi</CButton
                        >
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </transition>
              <transition name="slide">
                <div v-if="formChangePhoneNumber.show">
                  <CForm @submit.prevent="submitChangePhoneNumber()">
                    <CRow>
                      <CCol>
                        <CInput
                          placeholder="Nomor HP Baru"
                          type="number"
                          v-model="formChangePhoneNumber.phone_number"
                          :invalid-feedback="
                            validatorChangePhoneNumber.phone_number
                          "
                          :is-valid="
                            validatorChangePhoneNumber.phone_number
                              ? false
                              : true
                          "
                        >
                        </CInput>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton
                          size="sm"
                          color="warning"
                          @click="
                            formChangePhoneNumber.show =
                              !formChangePhoneNumber.show
                          "
                          >Tutup</CButton
                        >
                      </CCol>
                      <CCol col="6" class="text-right">
                        <CButton type="submit" size="sm" color="success"
                          >Simpan</CButton
                        >
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </transition>
            </CCardFooter>
          </template>
        </CWidgetIcon>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="6">
        <CCard class="p-0 m-0">
          <CCardBody class="p-0 m-0">
            <CForm @submit.prevent="submitChangePassword()">
              <CListGroup>
                <CListGroupItem active>Ubah Password</CListGroupItem>
                <CListGroupItem>
                  Password Lama

                  <CInput
                    class="mt-2"
                    placeholder="Password Lama"
                    type="password"
                    v-model="formResetPassword.old_password"
                    :invalid-feedback="validatorResetPassword.old_password"
                    :is-valid="
                      validatorResetPassword.old_password ? false : true
                    "
                  >
                  </CInput>
                </CListGroupItem>
                <CListGroupItem>
                  Password Baru

                  <CInput
                    class="mt-2"
                    placeholder="Password Baru"
                    type="password"
                    v-model="formResetPassword.password"
                    :invalid-feedback="validatorResetPassword.password"
                    :is-valid="validatorResetPassword.password ? false : true"
                  >
                  </CInput>
                </CListGroupItem>
                <CListGroupItem>
                  Ulangi Password Baru

                  <CInput
                    class="mt-2"
                    placeholder="Ulangi Password Baru"
                    type="password"
                    v-model="formResetPassword.password_confirmation"
                    :invalid-feedback="
                      validatorResetPassword.password_confirmation
                    "
                    :is-valid="
                      validatorResetPassword.password_confirmation
                        ? false
                        : true
                    "
                  >
                  </CInput>
                </CListGroupItem>
                <CListGroupItem>
                  <CButton
                    type="submit"
                    color="primary"
                    size="sm"
                    class="float-right"
                  >
                    <CIcon name="cil-save" /> Simpan</CButton
                  >
                </CListGroupItem>
              </CListGroup>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="12" sm="6"> <Support /> </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "../../apis/api";
import Support from "../additional/Support.vue";
export default {
  name: "Breadcrumbs",
  components: { Support },
  data() {
    return {
      formOtpPhoneNumber: {
        otp: "",
        show: false,
      },
      formOtpEmail: {
        otp: "",
        show: false,
      },

      formChangeEmail: {
        email: "",
        show: false,
      },
      validatorChangeEmail: {
        email: "",
      },
      formVerificationEmail: {
        otp: "",
        show: false,
      },
      validatorVerificationEmail: {
        otp: "",
      },

      formChangePhoneNumber: {
        phone_number: "",
        show: false,
      },
      validatorChangePhoneNumber: {
        phone_number: "",
      },
      formVerificationPhoneNumber: {
        otp: "",
        show: false,
      },
      validatorVerificationPhoneNumber: {
        otp: "",
      },

      formResetPin: {
        password: "",
        pin: "",
        show: false,
      },
      validatorResetPin: {
        password: "",
        pin: "",
      },

      formResetPassword: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      validatorResetPassword: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
    };
  },

  computed: {
    profile() {
      return this.$store.state.user;
    },
  },
  methods: {
    resetValidator() {
      this.validatorResetPin.password = "";
      this.validatorResetPin.pin = "";
      this.validatorChangePhoneNumber.phone_number = "";
      this.validatorChangeEmail.email = "";
      this.validatorResetPassword.old_password = "";
      this.validatorResetPassword.password = "";
      this.validatorResetPassword.password_confirmation = "";
      this.validatorVerificationEmail.otp = "";
      this.validatorVerificationPhoneNumber.otp = "";
    },
    async sendVerificationEmail() {
      let loader = await this.$loading.show();
      this.resetValidator();
      try {
        await axios.post("/user/send_otp", {
          type: "email",
        });
        this.$notify({
          type: "success",
          text: "Masukkan OTP sesuai dengan Kode yang anda terima",
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Terjadi kesalahan, silahkan kirim ulang",
        });
      }
      await loader.hide();
    },
    async submitVerificationEmail() {
      let loader = await this.$loading.show();

      this.resetValidator();
      try {
        const { data } = await axios.post("/user/verif_email", {
          otp: this.formVerificationEmail.otp,
        });
        this.$notify({
          type: "success",
          text: "Email berhasil diverifikasi",
        });

        this.$store.commit("set", ["user", data.data]);
        this.formVerificationEmail.show = false;
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.otp) {
            this.validatorVerificationEmail.otp = errors.otp.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
    async sendVerificationPhoneNumber(method) {
      let loader = await this.$loading.show();

      this.resetValidator();
      try {
        await axios.post("/user/send_otp", {
          type: method,
        });
        this.$notify({
          type: "success",
          text: "Masukkan OTP sesuai dengan Kode yang anda terima",
        });
      } catch (error) {
        if (error.response.data) {
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
    async submitVerificationPhoneNumber() {
      let loader = await this.$loading.show();

      this.resetValidator();
      try {
        const { data } = await axios.post("/user/verif_phone_number", {
          otp: this.formVerificationPhoneNumber.otp,
        });
        this.$notify({
          type: "success",
          text: "Nomor HP berhasil diverifikasi",
        });

        this.$store.commit("set", ["user", data.data]);
        this.formVerificationPhoneNumber.show = false;
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.otp) {
            this.validatorVerificationPhoneNumber.otp = errors.otp.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },

    async submitChangePassword() {
      let loader = await this.$loading.show();

      this.resetValidator();
      if (
        this.formResetPassword.password !==
        this.formResetPassword.password_confirmation
      ) {
        this.validatorResetPassword.password_confirmation =
          "Harus sama dengan Password baru";
        return;
      }
      try {
        await axios.post("/user/change_password", this.formResetPassword);
        this.formResetPassword.old_password = "";
        this.formResetPassword.password = "";
        this.formResetPassword.password_confirmation = "";

        await this.$notify({
          type: "success",
          text: "Password berhasil diubah",
        });
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.old_password) {
            this.validatorResetPassword.old_password =
              errors.old_password.join(" & ");
          }
          if (errors.password) {
            this.validatorResetPassword.password = errors.password.join(" & ");
          }
          if (errors.password_confirmation) {
            this.validatorResetPassword.password_confirmation =
              errors.password_confirmation.join(" & ");
          }

          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
    async submitResetPin() {
      let loader = await this.$loading.show();
      this.resetValidator();
      try {
        const { data } = await axios.post("/user/add_pin", {
          password: this.formResetPin.password,
          pin: this.formResetPin.pin,
        });
        this.$store.commit("set", ["user", data.data]);
        this.formResetPin.show = false;

        await this.$notify({
          type: "success",
          text: "PIN berhasil diubah",
        });
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.pin) {
            this.validatorResetPin.pin = errors.pin.join(" & ");
          }
          if (errors.password) {
            this.validatorResetPin.password = errors.password.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
    async submitChangeEmail() {
      let loader = await this.$loading.show();
      this.resetValidator();
      try {
        const { data } = await axios.post("/user/change_email", {
          email: this.formChangeEmail.email,
        });
        this.$store.commit("set", ["user", data.data]);
        this.formChangeEmail.show = false;

        await this.$notify({
          type: "success",
          text: "Email berhasil diubah",
        });
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.email) {
            this.validatorChangeEmail.email = errors.email.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
    async submitChangePhoneNumber() {
      let loader = await this.$loading.show();
      this.resetValidator();
      try {
        const { data } = await axios.post("/user/change_phone_number", {
          phone_number: this.formChangePhoneNumber.phone_number,
        });
        this.$store.commit("set", ["user", data.data]);
        this.formChangePhoneNumber.show = false;

        await this.$notify({
          type: "success",
          text: "Nomor HP berhasil diubah",
        });
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.phone_number) {
            this.validatorChangePhoneNumber.phone_number =
              errors.phone_number.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
  },
};
</script>

<style scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
